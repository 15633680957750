import { admAxiosCall } from "@api/adm";

export default {
  async FETCH_COMM_SELECTED({ commit }, payload) {
    const { data } = await admAxiosCall.fetchCmnCodesSelected(payload);
    commit("SET_COMM_SELECTED_DATA", { data: data.resData, key: payload.key });
    // if (data.resCd === "SQI0000") { // 성공
    //   commit("SET_COMM_SELECTED_DATA", { data: data.resData, key: payload.key });
    // } else {
    //   console.log("error");
    // }
  },

  async FETCH_SBD_SELECTED({ commit }, payload) {
    const { data } = await admAxiosCall.fetchCmnCodesSelected(payload);
    commit("SET_SBD_SELECTED_DATA", { data: data.resData, key: payload.key });
    // if (data.resCd === "SQI0000") { // 성공
    //   commit("SET_COMM_SELECTED_DATA", { data: data.resData, key: payload.key });
    // } else {
    //   console.log("error");
    // }
  },
  // LH관리자 메뉴 리스트
  async FETCH_LHW_NAV_MENU({ commit }, payload) {
    const { data } = await admAxiosCall.fetchLhwNavMenu(payload);
    commit("SET_LHW_NAV_MENU_DATA", data.resData); // 리스트
    // if (data.resCd === "SQI0000") { // 성공
    //   commit("SET_LHW_NAV_MENU_DATA", data.resData); // 리스트
    // } else {
    //   console.log("error");
    // }
    return data;
  },

  // 리스트 조회
  async FETCH_ADM_LIST({ commit }, payload) {
    const { data } = await admAxiosCall.fetchAdmList(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_ADM_LIST_ROWS_DATA", data.resData); // 리스트
    } else if (data.resCd === "SQI0002") {
      console.log("noData");
    } else {
      console.log("error");
    }
    return data;
  },
  // 관리자 관리 회원정보 조회
  async FETCH_MNGR_ID_INFO({ commit }, payload) {
    const { data } = await admAxiosCall.fetchMngrIdInfo(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_MNGR_ID_INFO_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
    return data;
  },
  // 관리자관리 정보 수정
  async FETCH_MNGR_ID_INFO_UPDATE({ commit }, payload) {
    const { data } = await admAxiosCall.fetchMngrIdInfoUpdate(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_MNGR_ID_INFO_UPDATE_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
    return data;
  },
  // 관리자 관리 정보 저장
  async FETCH_MNGR_ID_INFO_SAVE({ commit }, payload) {
    console.log(payload);
    const { data } = await admAxiosCall.fetchMngrIdInfoSave(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_MNGR_ID_INFO_SAVE_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_ADM_DELETE({ commit }, payload) {
    const { data } = await admAxiosCall.fetchMngrIdInfoDelete(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_MNGR_ID_INFO_DELETE_DATA", data.resData); // 삭제
    } else {
      console.log("error");
    }
    return data;
  },

  async FETCH_ADM_LOCK({ commit }, payload) {
    const { data } = await admAxiosCall.fetchMngrIdInLock(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_MNGR_ID_INFO_LOCK_DATA", data.resData); // 잠금
    } else {
      console.log("error");
    }
    return data;
  },

  // 컬럼 조회
  async FETCH_US_CONN_COLUMNS({ commit }, payload) {
    const res = await admAxiosCall.fetchUsConnColumns(payload);
    if (res.data) {
      commit("SET_US_CONN_LIST_COLUMN_DATA", res.data); // 리스트
    } else {
      console.log("error");
    }
  },

  // 리스트 조회
  async FETCH_US_CONN_LIST({ commit }, payload) {
    const res = await admAxiosCall.fetchUsConnList(payload);
    if (res.data) {
      commit("SET_US_CONN_LIST_ROWS_DATA", res.data); // 리스트
    } else {
      console.log("error");
    }
  },
  // 컬럼 조회
  async FETCH_ADM_ROL_COLUMNS({ commit }, payload) {
    const { data } = await admAxiosCall.fetchAdmRolColumns(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_ADM_ROL_COLUMNS_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
  },
  //  역할 정보 조회
  async FETCH_ADM_ROL_LIST({ commit }, payload) {
    const { data } = await admAxiosCall.fetchAdmRolList(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_ADM_ROL_LIST_ROWS_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
  },
  // 역할 정보 리스트 조회
  async FETCH_ADM_ROL_INFO({ commit }, payload) {
    const { data } = await admAxiosCall.fetchAdmRolInfo(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_ADM_ROL_INFO_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
  },
  async FETCH_ADM_ROL_SAVE({ commit }, payload) {
    const { data } = await admAxiosCall.fetchAdmRolSave(payload);
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_RESULT", { resData: data.resData }); // 리스트
    } else {
      console.log("error");
    }
    return data;
  },

  // 메뉴 리스트 조회
  async FETCH_MENU_LIST(ctx, payload) {
    const { data } = await admAxiosCall.fetchMenuList(payload);
    return data;
  },

  // 메뉴 상세 조회
  async FETCH_MENU_DTL(ctx, mnuId) {
    const { data } = await admAxiosCall.fetchMenuDtl(mnuId)
    return data
  },

  // 메뉴 사용여부 저장
  async SAVE_MENU_USYN(ctx, payload) {
    const { data } = await admAxiosCall.saveMenuUsyn(payload)
    return data
  },
};
