import { instanceWithAuth } from './index'

const config = {
  usrAdm: "mngr/",
};

export const usrAdmAxiosCall = {
  fetchUsrList(payload) {
    return instanceWithAuth.get(`${config.usrAdm}appusers`, {
      params: {
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
        usrId: payload.usrId,
        usrNm: payload.usrNm,
        hshId: payload.hshId,
        sbdId: payload.sbdId
      }
    });
  },
  fetchUsrInfo(payload) {
    return instanceWithAuth.get(`${config.usrAdm}appusers/${payload}`);
  },
  saveUsrInfo(payload) {
    return instanceWithAuth.put(`${config.usrAdm}appusers`, payload);
  },
};
export default { usrAdmAxiosCall };
