import Vue from "vue"
import axios from 'axios'
import JwtService from '@/auth/jwt/jwtService'
import app from '@/main'
import { msgAlertBox } from '@/utils/modal'
import { responseCodesToReturn } from '@/constants/api-response-code'
import store from '@/store'

const axiosIns = axios.create({
  // timeout: 1000,
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
  },
}) 

axiosIns.interceptors.request.use(
  config => {
    // 로딩바 표시안하거나 특정 위치에 로딩바를 표시하고 싶을 경우 noSpinner: true
    if (!config.noSpinner) {
      store.commit('cmn/SET_IS_LOADING', true)
    }
    return config
  },
  error => {
    store.commit('cmn/SET_IS_LOADING', false)
    return Promise.reject(error)
  },
)

axiosIns.interceptors.response.use(
  response => {
    store.commit('cmn/SET_IS_LOADING', false)
    if (response.data && responseCodesToReturn.includes(response.data.resCd)) {
      return response
    } else {
      msgAlertBox(app, response.data.resMsg)
      // msgAlertBox(app, '에러가 발생하였습니다.</br>다시 시도해주십시오.')
      throw new Error(`${response.data.resMsg}(${response.data.resCd})`)
    }
  },
  error => {
    store.commit('cmn/SET_IS_LOADING', false)
    console.error(error)
    msgAlertBox(app, '에러가 발생하였습니다.</br>다시 시도해주십시오.')
    return Promise.reject(error)
  }
)

export const useJwt = new JwtService(axios, {})
export const axiosInsWithAuth = useJwt.getAxiosWithAuth()

export const mockAxiosIns = axios.create({
  // timeout: 1000,
  baseURL: window.location.origin,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
  },
}) 
Vue.prototype.$http = axiosIns;
export default axiosIns
