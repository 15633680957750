import { instanceWithAuth } from './index'

export const reportAxiosCall = {
  fetchLogList(payload) {
    return instanceWithAuth.get(
      `mngr/log/sys-conn-log?mngrId=${payload.mngrId}&pageSize=${payload.pageSize}&pageNum=${payload.pageNum}`
    );
  },fetchBatchHstList(payload) {
    return instanceWithAuth.get('mngr/log/batchs', {
      params: {
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
        procNm: payload.procNm,
        podCd: payload.podCd,
        podHr: payload.podHr,
        usYn: payload.usYn
      }
    });
  },fetchBatchHstdtl(payload) {
    return instanceWithAuth.get(
      `mngr/log/batch-logs?procNm=${payload.procNm}&pageSize=${payload.pageSize}&pageNum=${payload.pageNum}`
    );
  },fetchBatchInfo(payload) {
    return instanceWithAuth.get(`mngr/log/batchs/${payload}`);
  },fetchCldOprtList(payload) {
    return instanceWithAuth.get('mngr/report/cloud-operation', {
      params: {
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
        stndYm: payload.stndYm,
        pclFctsSmr: payload.searchTerm
      }
    });
  },fetchCldOprtSave(formData) {
    return instanceWithAuth.post("mngr/report/cloud-operation", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },fetchCldOprtUpdate(formData) {
    return instanceWithAuth.put("mngr/report/cloud-operation", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },fetchCldOprtInfo(payload) {
    return instanceWithAuth.get(`mngr/report/cloud-operation/${payload}`);
  },fetchCldOprtDelete(stndYms) {
    return instanceWithAuth.delete(`mngr/report/cloud-operation`, { data: {stndYms} });
  },fetchCldOprtDown(payload) {
    return instanceWithAuth.get(`mngr/report/cloud-operation/attach-file/${payload.stndYm}`,{ responseType: 'blob'} );
	},fnGetInifTrtList(payload){
		return instanceWithAuth.get('report/inif-trt',{
				params: {
					pageSize: payload.pageSize,
					pageNum: payload.pageNum,
					searchTerm: payload.searchTerm,
					searchField: payload.searchField
				}
			}
		);
  }
};
export default { reportAxiosCall };
