import { reportAxiosCall } from "@api/report";
import { admAxiosCall } from "@api/adm";

export default {
  async FETCH_USCONN_LIST({ commit }, payload) {
    const { data } = await admAxiosCall.fetchAdmList(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_USCONN_LIST_ROWS_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_USCONN_HST({ commit }, payload) {
    const { data } = await reportAxiosCall.fetchLogList(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_RESULT", {
        resCd: data.resCd,
        resMsg: data.resMsg,
        resData: data.resData,
      });
      commit("SET_TOTCNT", { totCnt: data.resData[0].totCnt });
    } else if (data.resCd === "SQI0002") {
      commit("SET_RESULT", { resCd: data.resCd, resMsg: data.resMsg });
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_MNGR_INFO({ commit }, payload) {
    const { data } = await admAxiosCall.fetchMngrIdInfo(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_MNGR_INFO", data.resData);
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_BATCH_HST_LIST({ commit }, payload) {
    const { data } = await reportAxiosCall.fetchBatchHstList(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_ROWS", data.resData);
    } else if (data.resCd === "SQI0002") {
      commit("SET_RESULT", { resCd: data.resCd, resMsg: data.resMsg });
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_BATCH_HST_DTL({ commit }, payload) {
    const { data } = await reportAxiosCall.fetchBatchHstdtl(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_BATCH_HST_TABLED", data.resData);
    } else {
      console.log("error");
    }
    return data;
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async FETCH_BATCH_INFO({ commit }, payload) {
    const { data } = await reportAxiosCall.fetchBatchInfo(payload);
    if (data.resCd === "SQI0000") {
      return data;
    }
    console.log("error");
  },
  async FETCH_CLD_OPRT_LIST({ commit }, payload) {
    const { data } = await reportAxiosCall.fetchCldOprtList(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_CLD_OPRT_LIST_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
  },
  async FETCH_CLD_OPRT_SAVE({ commit }, formData) {
    const { data } = await reportAxiosCall.fetchCldOprtSave(formData);
    if (data.resCd === "SQI0000") {
      commit("SET_RESULT", data.resData);
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_CLD_OPRT_UPDATE({ commit }, formData) {
    const { data } = await reportAxiosCall.fetchCldOprtUpdate(formData);
    if (data.resCd === "SQI0000") {
      commit("SET_RESULT", data.resData);
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_CLD_OPRT_INFO({ commit }, payload) {
    const { data } = await reportAxiosCall.fetchCldOprtInfo(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_CLD_OPRT_INFO_DATA", data.resData);
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_CLD_OPRT_INFO_RESET({ commit }) {
    commit("FETCH_CLD_OPRT_INFO_RESET");
  },
  async FETCH_CLD_OPRT_DELETE({ commit }, payload) {
    const { data } = await reportAxiosCall.fetchCldOprtDelete(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
	},async FETCH_CLD_OPRT_DOWN({ commit }, payload) {
		const response = await reportAxiosCall.fetchCldOprtDown(payload);
		const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));

    // eslint-disable-next-line no-unused-vars
    // const contentDisposition = response.headers['content-disposition'];
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', payload.otxtFlNm);
		document.body.appendChild(link);
		link.click();
		link.remove();
		commit("SET_RESULT", { });
	},async FETCH_INIF_TRT_LIST({ commit }, payload) {
		const {data} = await reportAxiosCall.fnGetInifTrtList(payload);
		if (data.resCd === "SQI0000") {
			commit("SET_ROWS_CNT", data);
		}else if (data.resCd === "SQI0002") {
			commit("SET_RESULT", { resCd: data.resCd, resMsg: data.resMsg });
		} else {
			console.log("error");
		}
		return data;
  }
};
