export default {
  // API 가져올 옵션값
  commCodeData: {
    USR_SCH: ["이름", "아이디", "세대아이디"],
  },

  selectedData: {
    grpSn: "",
    sbdId: "",
    usrSch: "이름",
  },
  /**
   * 리스트 테이블 관련 state
   */
  tabled: {
    columns: [
      {
        label: "이름",
        field: "usrNm",
        thClass: "thClass p20",
        tdClass: "tdClass p20",
      },
      {
        label: "아이디",
        field: "usrId",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "역할",
        field: "rolNm",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "단지",
        field: "sbdNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "동",
        field: "dongDs",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "호수",
        field: "hoDs",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "상태",
        field: "usrSsNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    rows: [],
    // 검색
    searchTerm: "",
    totalRows: null,
    pageSize: 15,
    pageNum: 1,
  },
  result: {
    resCd: "",
    resMsg: "",
    resData: "",
  },
};
