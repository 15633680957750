import { sysSetAxiosCall } from "@api/sysSet";
import { cmnAxiosCall } from "@api/cmn";
import qs from "qs";

export default {
  // 리스트 조회
  async FETCH_H_NET_CO_LIST({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchHNetCoList(payload);
    if (data.resCd === "SQI0000") {
      // 성
      commit("SET_H_NET_CO_LIST_ROWS_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
  },
  // 홈넷사관리 저장
  async FETCH_H_NET_CO_INFO_SAVE({ commit }, payload) {
    if (payload.sbdIds) {
      payload.sbdIds = payload.sbdIds.map((v) => v.value);
    }
    const { data } = await sysSetAxiosCall.fetchHNetCoInfoSave(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log(data.resCd);
      console.log("error");
    }
    return data;
  },
  async FETCH_H_NET_CO_INFO_UPDATE({ commit }, payload) {
    if (payload.sbdIds) {
      payload.sbdIds = payload.sbdIds.map((v) => v.value);
    }
    const { data } = await sysSetAxiosCall.fetchHNetCoInfoUpdate(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log(data.resCd);
      console.log("error");
    }
    return data;
  },
  async FETCH_H_NET_CO_DELETE({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchHNetCoDelete(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  // 홈넷사 리셋
  async FETCH_H_NET_CO_INFO_RESET({ commit }) {
    commit("SET_H_NET_CO_INFO_RESET");
  },
  // 상세정보
  async FETCH_H_NET_CO_INFO({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchHNetCoInfo(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_H_NET_CO_INFO_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
  },
  // 홈넷사 API키생성
  async FETCH_H_NET_CO_API_KEY({ commit }) {
    const { data } = await sysSetAxiosCall.fetchHNetCoApiKey();
    if (data.resCd === "SQI0000") {
      commit("SET_H_NET_CO_API_KEY", data.resData); // 리스트
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_OTHER_SYS_COLUMNS({ commit }, payload) {
    const res = await sysSetAxiosCall.fetchOtherSysColumns(payload);
    if (res.data) {
      commit("SET_H_NET_CO_LIST_COLUMN_DATA", res.data); // 리스트
    } else {
      console.log("error");
    }
  },
  // 리스트 조회
  async FETCH_OTHER_SYS_LIST({ commit }, payload) {
    const res = await sysSetAxiosCall.fetchOtherSysList(payload);
    if (res.data) {
      commit("SET_H_NET_CO_LIST_ROWS_DATA", res.data); // 리스트
    } else {
      console.log("error");
    }
  },
  // 공지사항 종류 ( APP, LHW, SYS)
  async FETCH_PGM_DS_SELECTED({ commit }, payload) {
    const { data } = await cmnAxiosCall.fetchComnCode({cdGrpId:payload.key,uppCdDtlId:"ANN" });
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_BOARD_NOTICE_PGM_CD_DATA", {
        data: data.resData,
        key: payload.key,
      }); // 리스트
    } else {
      console.log("error");
    }
  },
  // 공지사항 리스트
  async FETCH_BOARD_NOTICE_LIST({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchBoardNoticeList(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_BOARD_NOTICE_LIST_ROWS_DATA", data.resData); // 리스트
    } else if (data.resCd === "SQI0002") {
      // 데이터가 없음
      commit("SET_BOARD_NOTICE_LIST_RESET");
    } else {
      console.log("error");
    }
  },
  // 공지사항 저장
  async FETCH_BOARD_NOTICE_SAVE({ commit }, payload) {
    const qsPayload = qs.stringify(payload); // JSON -> x-www-form-urlencoded 변환
    const { data } = await sysSetAxiosCall.fetchBoardNoticeSave(qsPayload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_BOARD_NOTICE_UPDATE({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchBoardNoticeUpdate(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  // 수정 할때 정보 가져옴
  async FETCH_NTC_FCTS_SN_INFO({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchnatSnInfo(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_BOARD_NOTICE_FCTS_SN_DATA", data.resData);
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_BOARD_NOTICE_DELETE({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchBoardNoticeDelete(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  // 서비스이용약관 리스트
  async FETCH_SERVICE_CLU_LIST({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchServiceCluList(payload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_SERVICE_CLU_LIST_ROWS_DATA", data.resData); // 리스트
    } else if (data.resCd === "SQI0002") {
      commit("SET_SERVICE_CLU_LIST_RESET");
    } else {
      console.log("error");
    }
  },
  // 서비스이용약관 상세
  async FETCH_SERVICE_CLU_INFO({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchServiceCluInfo(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_SERVICE_CLU_INFO_DATA", data.resData);
    } else {
      console.log("error");
    }
  },
  async FETCH_SERVICE_CLU_INFO_RESET({ commit }) {
    commit("SET_SERVICE_CLU_INFO_RESET");
  },
  // 서비스이용약관 저장
  async FETCH_SERVICE_CLU_SAVE({ commit }, payload) {
    const qsPayload = qs.stringify(payload);
    const { data } = await sysSetAxiosCall.fetchServiceCluSave(qsPayload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  // 서비스이용약관 수정
  async FETCH_SERVICE_CLU_UPDATE({ commit }, payload) {
    const qsPayload = qs.stringify(payload);
    const { data } = await sysSetAxiosCall.fetchServiceCluUpdate(qsPayload);
    if (data.resCd === "SQI0000") {
      // 성공
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  // 서비스이용약관 삭제
  async FETCH_SERVICE_CLU_DELETE({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchServiceCluDelete(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  // 연동시스템 리스트
  async FETCH_LNKG_SYS_LIST({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchLnkgSysList(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_LNKG_SYS_LIST_ROWS_DATA", data.resData);
    } else if (data.resCd === "SQI0002") {
      commit("SET_RESULT", { resData: data.resData });
    } else {
      console.log("error");
    }
    return data;
  },
  // 연동시스템 상세
  async FETCH_LNKG_SYS_INFO({ commit }, payload) {
    const { data } = await sysSetAxiosCall.fetchLnkgSysInfo(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_LNKG_SYS_INFO_DATA", data.resData);
    } else {
      console.log("error");
    }
  },
};
