// state에 선언된 변수에 값을 할당함.
export default {

  SET_USCONN_LIST_ROWS_DATA(state, payload) {
    // 가변적(필터)으로 리스트 로드
    if (state.selectedData.filterOption) {
      if (state.selectedData.filterOption.length === 0) { // 선택된 필터 값이 없으면 리셋
        state.tabled.columns = [];
        state.tabled.columns = state.tabled.basicColumns;
      } else {
        state.tabled.columns = [];
        state.tabled.columns = state.selectedData.filterOption.map(v => ({
          field: v.value,
          label: v.text,
          thClass: 'thClass',
          tdClass: 'tdClass',
        }));
        state.tabled.columns.push({
          label: '수정',
          field: 'edt',
          thClass: 'thClass',
          tdClass: 'tdClass',
        });
      }
    }
    state.tabled.rows = payload.map(v => ({
      mngrNm: v.mngrNm,
      mngrId: v.mngrId,
      grpNm: v.grpNm,
      sbdNm: v.sbdNm,
      frmNm: v.frmNm,
      rolNm: v.rolNm,
    }));
    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_RESULT(state, payload) {
    state.result = payload;
  },
  SET_TOTCNT(state, payload) {
    state.tabled.totalRows = payload.totCnt;
  },
  SET_MNGR_INFO(state, payload) {
    state.mngrInfo = payload;
  },
  SET_ROWS(state, payload) {
    state.tabled.rows = payload;
    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_ROWS_CNT(state, payload) {
    state.tabled.rows = payload.resData;
    state.tabled.totalRows = payload.totCnt;
  },
  SET_BATCH_HST_TABLED(state, payload) {
    state.batchHstTabled.rows = payload;
    state.batchHstTabled.totalRows = payload[0].totCnt;
  },
  SET_CLD_OPRT_LIST_DATA(state, payload) {
    state.tabled.rows = [];
    state.tabled.rows = payload.map(v => ({
      stndYm: v.stndYm,
      pclFctsSmr: v.pclFctsSmr,
      otxtFlNm: v.otxtFlNm,
      svFlNm: v.svFlNm,
    }));
    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_CLD_OPRT_INFO_DATA(state, payload) {
    console.log(payload);
    state.cldOprtInfo.stndYm = payload.stndYm;
    state.cldOprtInfo.pclFctsSmr = payload.pclFctsSmr;
    state.cldOprtInfo.attachFile = payload.attachFile;
    state.cldOprtInfo.otxtFlNm = payload.otxtFlNm;
    state.cldOprtInfo.svFlNm = payload.svFlNm;
  },
  FETCH_CLD_OPRT_INFO_RESET(state) {
    state.cldOprtInfo.stndYm = "";
    state.cldOprtInfo.pclFctsSmr = "";
    state.cldOprtInfo.attachFile = "";
    state.cldOprtInfo.otxtFlNm = "";
    state.cldOprtInfo.svFlNm = "";
  },
};
