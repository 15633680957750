import Vue from 'vue'
import VueRouter from 'vue-router'
import adm from '@/router/adm'
import cmn from '@/router/cmn'
import login from '@/router/login'
import set from '@/router/set'
import sysSet from '@/router/sysSet'
import usrAdm from '@/router/usrAdm'
import dashboard from '@/router/dashboard'
import report from '@/router/report'
import { cookie } from '@/utils/cookie'
import { msgAlertBox } from '@/utils/modal'

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush
    .call(this, location)
    .catch(() => window.location.reload());
};

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...adm,
    ...cmn,
    ...login,
    ...set,
    ...sysSet,
    ...usrAdm,
    ...dashboard,
    ...report,
  ],
});

router.beforeEach(async (to, from, next) => {
  // https://github.com/vuejs/vue-router/issues/2672
  await Vue.nextTick()
  if (to.meta.requiresAuth) {
    const accessToken = cookie.getAccessToken()
    if (!!accessToken) {
      next()
      return
    } else {
      const app = router.app
      msgAlertBox(app, app.$t('message.error.requiredLogin'))
      next("/login")
    }
  } else {
    next()
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
