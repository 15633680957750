import { setAxiosCall } from "@api/set";

export default {
  async FETCH_MY_INFO({ commit }, usrId) {
    const param = { mngrId: usrId };
    let result = {};
    await setAxiosCall
      .fetchMyInfo(param)
      .then((res) => {
        if (res.data.resCd === "SQI0000") {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
            resData: res.data.resData,
          });
          result = res.data;
        } else {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
          });
        }
        // eslint-disable-next-line no-unused-vars
      })
      .catch((error) => {
        commit("SET_RESULT", { resMsg: "처리 중 오류가 발생하였습니다." });
      });
    return result;
  },
  async SAVE_MY_INFO({ commit }, param) {
    const infoData = {};
    infoData.mngrNm = param.mngrNm;
    infoData.mngrId = param.mngrId;
    infoData.mngrTlno = param.mngrTlno;
    infoData.mngrMblTlno = param.mngrMblTlno;
    infoData.mngrEmlAdr = param.mngrEmlAdr;
    infoData.emlRcvYn = param.emlRcvYn;
    await setAxiosCall
      .saveMyInfo(infoData)
      .then((res) => {
        if (res.data.resCd === "SQI0000") {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
            resData: res.data.resData,
          });
        } else {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
          });
        }
        // eslint-disable-next-line no-unused-vars
      })
      .catch((error) => {
        commit("SET_RESULT", { resMsg: "처리 중 오류가 발생하였습니다." });
      });
  },
  async UPDATE_PWD({ commit }, param) {
    await setAxiosCall
      .updatePwd(param)
      .then((res) => {
        if (res.data.resCd === "SQI0000") {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
            resData: res.data.resData,
          });
        } else {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
          });
        }
        // eslint-disable-next-line no-unused-vars
      })
      .catch((error) => {
        commit("SET_RESULT", { resMsg: "처리 중 오류가 발생하였습니다." });
      });
  },
};
