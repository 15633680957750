import { instance } from './index'

export const authAxiosCall = {
  fetchLogin(usrInfo) {
    return instance.post('/cert/login', usrInfo)
  },
  fetchResetPassword(usrNewInfo){
    return instance.put('/cert/resetPassword', usrNewInfo)
  },
  // SMS 인증번호 전송
  async sendMobCtf(payload) {
    return instance.post('/cert/certSms', {
      usrId: payload.usrId,
      usrPwd: payload.usrPwd,
      usrMblNo: payload.usrMblNo
    });
  },
  // SMS 인증번호 검증
  async checkMobCtf(payload) {
    return instance.post('/cert/checkSms', {
      usrId: payload.usrId,
      usrPwd: payload.usrPwd,
      usrMblNo: payload.usrMblNo,
      smsCfmChr: payload.smsCfmChr
    });
  },
}

export default { authAxiosCall }
