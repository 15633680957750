import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// Modules
import cmn from "./cmn";
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import adm from './adm'
import set from './set'
import sysSet from './sysSet'
import usrAdm from './usrAdm'
import auth from './auth'
import report from './report'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        adm,
        set,
        sysSet,
        usrAdm,
        auth,
        cmn,
        report,
    },
    plugins: [
        // 새로고침해도 vuex 초기화 X
        createPersistedState({
            paths: ['cmn'],
        })
    ],
    strict: process.env.DEV,
})