import { authAxiosCall } from '@/api/auth'
import { cookie } from '@/utils/cookie'
import { useJwt } from '@/libs/axios';

export default {
  namespaced: true, // 모듈일 경우에는 namespaced를 true로 반드시 설정해야 한다.
  state: {
    // 로그인 관련
    usrId: '',
    rolId: '',
    isAuthenticated: false,
    accessToken: '',
    refreshToken: '',
  },
  getters: {
    getIsAuthenticated(state) {
      return state.isAuthenticated
    }
  },
  mutations: {
    SET_LOGIN_DATA(state, payload) {
      state.usrId = payload.usrId
      state.rolId = payload.rolId
    },
    SET_IS_AUTHENTICATED(state, payload) {
      state.isAuthenticated = payload
    },
    SET_CLEAR_DATA(state) {
      useJwt.deleteRefreshToken({ data: { refshTkn: cookie.getRefreshToken() } });
      state.usrId = ''
      state.rolId = ''
      state.isAuthenticated = false
      cookie.delAccessToken()
      cookie.delRefreshToken()
      cookie.delRememberId()
    },
    SET_MOB_CTF_USR_ID(state, { mobCtfUsrId }) {
      state.mobCtfUsrId = mobCtfUsrId
    },
  },
  actions: {
    async login({ commit }, { usrId, usrPwd, rememberId }) {
      const usrInfo = { usrId, usrPwd }
      const { data } = await authAxiosCall.fetchLogin(usrInfo)

      commit('SET_IS_AUTHENTICATED', true)
      cookie.saveAccessToken(data.resData.accessToken);
      cookie.saveRefreshToken(data.resData.refreshToken);
      cookie.saveUsrId(data.resData.usrId)

      // 아이디 기억하기
      if (rememberId === "Y") {
        cookie.saveRememberId(data.resData.usrId)
      } else {
        cookie.delRememberId(data.resData.usrId)
      }

      return data
    },
    async resetPassword(_, usrNewInfo) {
      const { data } = await authAxiosCall.fetchResetPassword(usrNewInfo)
      return data
    },
    
    async sendMobCtf(_, payload) {
      const { data } = await authAxiosCall.sendMobCtf(payload);
      return data;
    },
    async checkMobCtf(_, payload) {
      const { data } = await authAxiosCall.checkMobCtf(payload);
      return data;
    },
    
    async updateRefreshToken(_, payload) {
      await useJwt.updateRefreshToken({
        refshTkn: payload.refreshToken,
        oldRefshTkn: payload.oldRefreshToken
      });

      cookie.saveAccessToken(payload.accessToken);
      cookie.saveRefreshToken(payload.refreshToken);
    },
    
    async udpateMobCtfUsrId({ commit }, payload) {
      commit('SET_MOB_CTF_USR_ID', { mobCtfUsrId: payload })
    },

    logout({ commit }) {
      commit('SET_CLEAR_DATA')
    },
  },
}
