// state에 선언된 변수에 값을 할당함.
export default {

  SET_ADM_LIST_ROWS_DATA(state, payload) {
    state.tabled.rows = payload.map(v => ({
      nm: v.user,
      id: v.id,
      area: v.type,
      sbd: v.comments_count,
      agt: v.points,
      tlno: v.time,
      eml: v.domain,

    }));
  },
  SET_RESULT(state, payload) {
    state.result = payload;
  },
};
