import inifTrt from "@/views/report/inifTrt.vue";

export default [
	{
	  path: "/report/usConn/usConnList",
	  name: "us-conn-list",
	  component: () => import("@/views/report/usConn/usConnList.vue"),
	  meta: {requiresAuth: true}
	},{
	  path: "/report/usConn/usConnHst",
	  name: "us-conn-hst",
	  component: () => import("@/views/report/usConn/usConnHst.vue"),
	  meta: {requiresAuth: true}
	},{
	  path: "/report/batchHst/batchHstList",
	  name: "batch-hst-list",
	  component: () => import("@/views/report/batchHst/batchHstList.vue"),
	  meta: {requiresAuth: true}
	},{
	  path: "/report/batchHst/batchHstDtl",
	  name: "batch-hst-dtl",
	  component: () => import("@/views/report/batchHst/batchHstDtl.vue"),
	  meta: {requiresAuth: true}
	},{
	  path: "/report/cldOprt/cldOprtList",
	  name: "cld-oprt-list",
	  component: () => import("@/views/report/cldOprt/cldOprtList.vue"),
	  meta: {requiresAuth: true}
	},{
	  path: "/report/cldOprt/cldOprtRgsUpd",
	  name: "cld-oprt-rgsUpd",
	  component: () => import("@/views/report/cldOprt/cldOprtRgsUpd.vue"),
	  meta: {requiresAuth: true}
	},{
		path: "/report/inifTrt",
		name: "report-inifTrt",
		component: inifTrt
	}
];
