import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
// 외부 라이브러리 로드
import LoadScript from "vue-plugin-load-script";

import VueCookies from "vue-cookies";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// 폼 유효성 검사 라이브러리
import "./plugins/vee-validate";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/vue-js-modal";

// alert창
import "@/libs/sweet-alerts";

// 다국어 처리
import i18n from './libs/i18n'

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// 외부 라이브러리
Vue.use(LoadScript);
Vue.use(VueCookies);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = true;
Vue.config.devtools = true;

export default new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n
}).$mount("#app");
