export default {
  getNavMenu(state) {
    return state.navMenu;
  },
  getMnuFncCds(state) {
    return state.mnuFncCds;
  },
  getClkMnuId(state) {
    return state.clkMnuId;
  },
  getClkMnuNm(state) {
    return state.clkMnuNm;
  },
};
