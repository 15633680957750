import { cmnAxiosCall } from "@api/cmn";

export default {
  // 네비 메뉴 리스트
  async FETCH_NAV_MENU({ commit }, payload) {
    const { data } = await cmnAxiosCall.fetchNavMenu(payload);
    // console.log(data)
    if (data.resCd === "SQI0000") { // 성공
      commit("SET_CMN_NAV_MENU_DATA", data.resData); // 리스트
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_CLK_MNUID({ commit }, payload) {
    commit("SET_CLK_MNUID", payload);
  },
  async setClkMnuNm({ commit }, payload) {
    commit("SET_CLK_MNUNM", payload);
  },
  // eslint-disable-next-line no-unused-vars
  async INSERT_MNU_LOG(ctx, payload) {
    // eslint-disable-next-line no-unused-vars
    const { data } = await cmnAxiosCall.insertMnuLog(payload)
    return data
  },
};
