<template>
	<div>
		<b-card-code title="개인정보 취급 내역">
			<div>
				<b-row>
					<b-col md="2">
						<v-select
							v-model=rqstPrm.type
							:options=srchTypeList
							label="text" placeholder="선택하세요"
						/>
					</b-col>
					<b-col md="2">
						<b-input-group size="md">
							<b-form-input
								v-model="commTabled.searchTerm"
								placeholder="검색"
								type="text"
								class="d-inline-block"
								@keyup.enter="fnGetInifTrtList"
							/>
							<b-input-group-append>
								<b-button @click="fnGetInifTrtList">검색</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-col>
				</b-row>
			</div>
		</b-card-code>
		<b-card-code title="목록">
			<div>
				<b-row>
					<b-col cols="12">
					<cmn-tbl
						:columns=cols
						:rows="commTabled.rows"
						:total-rows="commTabled.totalRows"
						:schOption="rqstPrm.type.value"
						:searchTerm="commTabled.searchTerm"
						:select-yn="{
							enabled: false,
							selectOnCheckboxOnly: true,
							disableSelectInfo: true,
							selectAllByGroup: true,
						  }"
					  :btn-yn="false"
					/>					
					</b-col>
				</b-row>
			</div>
		</b-card-code>		
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BCol, BRow, BButton,BInputGroup,BInputGroupAppend,BFormInput } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import CmnTbl from "@/components/cmn/cmnFrmTbl.vue";

import { mapGetters } from "vuex";

export default {
	components: {
		BCardCode
		,BRow ,BCol
		,vSelect
		,CmnTbl
		,BInputGroup ,BInputGroupAppend
		,BFormInput
		,BButton		
	},data() {
		return {
			srchTypeList: [{value:"picId",text:"열람자"},{value:"inifOwnrId",text:"소유자"},{value:"picConnIpad",text:"IP 주소"}]
			,rqstPrm: {type: {value:"picId",text:"열람자"}}
			,cols:[
				 {label: "열람자",field: "picId",thClass: "thClass p25",tdClass: "tdClass p25"}
				,{label: "소유자",field: "inifOwnrId",thClass: "thClass p25",tdClass: "tdClass p25"}
				,{label: "접속IP",field: "picConnIpad",thClass: "thClass p25",tdClass: "tdClass p25"}
			]
		};
	},computed: {
    ...mapGetters({
//      commSelected: "report/getCommCodeData",
//      selectedData: "report/getSelectedCodeData",
      commTabled: "report/getTabledCodeData"
    })	
	},created(){
		this.fnGetInifTrtList();
	},methods: {
		fnGetInifTrtList: async function(){
			let param = {
				searchTerm: this.commTabled.searchTerm,
				searchField: this.rqstPrm.type.value,
				pageSize: this.commTabled.pageSize,
				pageNum: this.commTabled.pageNum
			};
			const res = await this.$store.dispatch("report/FETCH_INIF_TRT_LIST",param);
			if (res.resCd === "SQI0002") {
				utils.fnAlert(this, "데이터가 존재하지 않습니다.");
			}
		}
	}
};
</script>

<style scoped></style>
