import { instanceWithAuth } from './index'

const config = {
  set: "mngr/",
};

export const setAxiosCall = {
  fetchUsrColumns() {
    return instanceWithAuth.post("list");
  },
  fetchUsrList() {
    return instanceWithAuth.post("list");
  },
  fetchMyInfo(usrId) {
    return instanceWithAuth.get(`${config.set}myinfo`, usrId);
  },
  saveMyInfo(param) {
    return instanceWithAuth.put(`${config.set}myinfo`, param);
  },
  updatePwd(param) {
    return instanceWithAuth.put(`/cert/change-password`, param);
  },
};
export default { setAxiosCall };
