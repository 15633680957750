export default {
  getCommCodeData(state) {
    return state.commCodeData;
  },
  getTabledCodeData(state) {
    return state.tabled;
  },
  getSelectedCodeData(state) {
    return state.selectedData;
  },
  getnatSnInfo(state) {
    return state.natSnInfo;
  },
  getHNetCoInf(state) {
    return state.hNetCoInfo;
  },
  getServiceCluInfo(state) {
    return state.serviceCluInfo;
  },
  getLnkgSysInfo(state) {
    return state.lnkgSysInfo;
  },
};
