export default [
     {
          path: "/sysSet/hNetCo/hNetCoList",
          name: "h-net-co-list",
          component: () => import ("@/views/sysSet/hNetCo/hNetCoList"),
	},{
          path: "/sysSet/hNetCo/hNetCoDtl",
          name: "h-net-co-dtl",
          component: () => import ("@/views/sysSet/hNetCo/hNetCoDtl.vue"),
	},{
          path: "/sysSet/hNetCo/hNetCoRgsUpd",
          name: "h-net-co-rgs",
          component: () => import ("@/views/sysSet/hNetCo/hNetCoRgsUpd.vue"),
	},{
          path: "/sysSet/lnkgSys/lnkgSysList",
          name: "lnkg-sys-list",
          component: () =>import ("@/views/sysSet/lnkgSys/lnkgSysList.vue"),
	},{
          path: "/sysSet/lnkgSys/lnkgSysDtl",
          name: "lnkg-sys-dtl",
          component: () => import ("@/views/sysSet/lnkgSys/lnkgSysDtl.vue"),
	},{
          path: "/sysSet/boardNotice/boardNoticeList",
          name: "board-notice-list",
          component: () => import ("@/views/sysSet/boardNotice/boardNoticeList"),
	},{
          path: "/sysSet/boardNotice/boardNoticeDtl",
          name: "board-notice-dtl",
          component: () => import ("@/views/sysSet/boardNotice/boardNoticeDtl"),
     },{
          path: "/sysSet/serviceClu/serviceCluList",
          name: "service-clu-list",
          component: () => import ("@/views/sysSet/serviceClu/serviceCluList"),
	},{
          path: "/sysSet/serviceClu/serviceCluRgsUpd",
          name: "service-clu-rgs",
          component: () =>import ("@/views/sysSet/serviceClu/serviceCluRgsUpd"),
	},{
          path: "/sysSet/board/:natTpCd",
          name: "board-list",
          component: () => import ("@/views/sysSet/board/boardList")
     },{
          path: "/sysSet/boardNotice/boardNoticeRgsUpd",
          name: "board-notice-rgs",
          component: () => import ("@/views/sysSet/boardNotice/boardNoticeRgsUpd"),
     },{
          path: "/sysSet/rsd-annc",
          name: "rsd-annc",
          component: () => import ("@/views/sysSet/board/rsdAnnc.vue")
     },{
          path: "/sysSet/block-ip",
          name: "block-ip",
          component: () => import ("@/views/sysSet/security/block.vue")
     }
];