import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'

export default {
  namespaced: true, // 모듈일 경우에는 namespaced를 true로 반드시 설정해야 한다.
  state,
  getters,
  mutations,
  actions,
}
