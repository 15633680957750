import axios from 'axios'
import JwtService from '@/auth/jwt/jwtService'
import app from '@/main'
import { msgAlertBox } from '@/utils/modal'
import { ApiResponseCode } from '@/constants/api-response-code'

const instance = axios.create({
  // timeout: 1000,
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
  },
}) 

instance.interceptors.response.use(
  response => {
    if (response.data && response.data.resCd === ApiResponseCode.SUCCESS) {
      return response
    } else {
      msgAlertBox(app, response.data.resMsg)
      throw new Error(response.data.resCd)
    }
  },
  error => {
    console.error(error)
    msgAlertBox(app, app.$t('message.error.systemError'))
    return Promise.reject(error)
  }
)

const useJwt = new JwtService(axios, {})
const instanceWithAuth = useJwt.getAxiosWithAuth()

export { instance, instanceWithAuth }
