export default {
  getCommCodeData(state) {
    return state.commCodeData;
  },
  getTabledCodeData(state) {
    return state.tabled;
  },
  getMngrIdInfoData(state) {
    return state.mngrIdInfo;
  },
  getSelectedCodeData(state) {
    return state.selectedData;
  },
  getSelectedListBoolean(state) {
    return state.selectedListBoolean;
  },
  getResult(state) {
    return state.result;
  },
  getAdmRolInfoData(state) {
    return state.rolIdInfo;
  },
  getRolTabledCodeData(state) {
    return state.rolTabled;
  },
  getLhwNavMenu(state) {
    return state.lhwNavMenu;
  },
};
