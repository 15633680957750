import { usrAdmAxiosCall } from "@/api/usrAdm";

export default {
  async FETCH_USR_LIST({ commit }, payload) {
    const { data } = await usrAdmAxiosCall.fetchUsrList(payload);
    if (data.resCd === "SQI0000") {
      commit("SET_USR_LIST_ROWS_DATA", data);
    } else if (data.resCd === "SQI0002") {
      commit("SET_RESULT", { resCd: data.resCd, resMsg: data.resMsg });
    } else {
      console.log("error");
    }
    return data;
  },
  async FETCH_USR_INFO({ commit }, payload) {
    const res = await usrAdmAxiosCall.fetchUsrInfo(payload);
    if (res.data) {
      commit("SET_RESULT", res);
      return res.data;
    } else {
      console.log("error");
    }
  },
  async SAVE_USR_INFO({ commit }, payload) {
    await usrAdmAxiosCall
      .saveUsrInfo(payload)
      .then((res) => {
        if (res.data.resCd === "SQI0000") {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
            resData: res.data.resData,
          });
        } else {
          commit("SET_RESULT", {
            resCd: res.data.resCd,
            resMsg: res.data.resMsg,
          });
        }
        // eslint-disable-next-line no-unused-vars
      })
      .catch((error) => {
        commit("SET_RESULT", { resMsg: "처리 중 오류가 발생하였습니다." });
      });
  },
};
