import VueCookies from 'vue-cookies';
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'

/**
 * 쿠키 관리 모듈입니다.
 */
const cookie = {
  /**
   * 액세스 토큰을 저장합니다.
   * @param {string} value - 저장할 액세스 토큰 값입니다.
   */
  saveAccessToken(value) {
    VueCookies.set(
      jwtDefaultConfig.cookieAccessTokenKeyName,
      value,
      jwtDefaultConfig.accessTokenExpireTime
    );
  },
  /**
   * 리프레시 토큰을 저장합니다.
   * @param {string} value - 저장할 리프레시 토큰 값입니다.
   */
  saveRefreshToken(value) {
    VueCookies.set(
      jwtDefaultConfig.cookieRefreshTokenKeyName,
      value,
      jwtDefaultConfig.refreshTokenExpireTime
    );
  },
  /**
   * rememberId를 저장합니다.
   * @param {string} value - 저장할 rememberId 값입니다.
   */
  saveRememberId(value) {
    VueCookies.set("rememberId", value, '1d');
  },
  /**
   * usrId를 저장합니다.
   * @param {string} value - 저장할 usrId 값입니다.
   */
  saveUsrId(value) {
    VueCookies.set("usrId", value, '1d');
  },

  /**
   * 액세스 토큰을 가져옵니다.
   * @returns {string|null} - 액세스 토큰 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getAccessToken() {
    return VueCookies.get(jwtDefaultConfig.cookieAccessTokenKeyName);
  },
  /**
   * 리프레시 토큰을 가져옵니다.
   * @returns {string|null} - 리프레시 토큰 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getRefreshToken() {
    return VueCookies.get(jwtDefaultConfig.cookieRefreshTokenKeyName);
  },

  /**
   * 액세스 토큰을 삭제합니다.
   */
  delAccessToken() {
    VueCookies.remove(jwtDefaultConfig.cookieAccessTokenKeyName);
  },
  /**
   * 리프레시 토큰을 삭제합니다.
   */
  delRefreshToken() {
    VueCookies.remove(jwtDefaultConfig.cookieRefreshTokenKeyName);
  },
  /**
   * rememberId를 삭제합니다.
   */
  delRememberId() {
    VueCookies.remove("rememberId");
  },
};

export { cookie };
