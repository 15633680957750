export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
  {
    path: "/login/resetPwd",
    name: "resetPwd",
    component: () => import("@/views/login/resetPwd.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
  {
    path: "/pages-profile",
    name: "pages-profile",
    component: () => import("@/views/login/login.vue"),
    meta: {
      layout: "full",
    },
  },
];
