export default [{
  path: "/adm/mnu/mnuList",
  name: "mnu-list",
  component: () =>
    import("@/views/adm/mnu/mnuList.vue"),
  meta: {
    requiresAuth: true,
  },
},
{
  path: "/adm/mnu/mnuDtl",
  name: "mnu-dtl",
  component: () =>
    import("@/views/adm/mnu/mnuDtl.vue"),
  meta: {
    requiresAuth: true,
  },
},
{
  path: "/adm/adm/admList",
  name: "adm-list",
  component: () =>
    import("@/views/adm/adm/admList.vue"),
  meta: {
    requiresAuth: true,
  },
},
{
  path: "/adm/adm/admDtl",
  name: "adm-dtl",
  component: () =>
    import("@/views/adm/adm/admDtl.vue"),
  meta: {
    requiresAuth: true,
  },
},
{
  path: "/adm/adm/admRgsUpd",
  name: "adm-rgs-upd",
  component: () =>
    import("@/views/adm/adm/admRgsUpd.vue"),
  props: true,
  meta: {
    requiresAuth: true,
  },
},
{
  path: "/adm/admRol/admRolList",
  name: "adm-rol-list",
  component: () =>
    import("@/views/adm/admRol/admRolList.vue"),
  meta: {
    requiresAuth: true,
  },
},
{
  path: "/adm/admRol/admRolRgsUpd",
  name: "adm-rol-upd",
  component: () =>
    import("@/views/adm/admRol/admRolRgsUpd.vue"),
  meta: {
    requiresAuth: true,
  },
},
{
  path: "/adm/admRol/admRolDtl",
  name: "adm-rol-dtl",
  component: () =>
    import("@/views/adm/admRol/admRolRgsUpd.vue"),
  meta: {
    requiresAuth: true,
  },
},
];
