export default {
  commCodeData: {
    BATCH_SCH: ["프로시저명", "주기코드", "주기시간"],
    US_YN: ["Y", "N"],
  },
  selectedData: {
    filterOption: null,
    gasSplTpCd: { value: null, text: "전체" },
    iotDvcTpCd: { value: null, text: "전체" },
    grpDsCd: { value: null, text: "전체" },
    stndYm: "",
    batchSch: "프로시저명",
    usYn: "",
  },
  tabled: {
    cldOprtColum: [
      {
        label: "등록일",
        field: "stndYm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "제목",
        field: "pclFctsSmr",
        thClass: "thClass p40",
        tdClass: "tdClass p40",
      },
      {
        label: "파일보기",
        field: "otxtFlNm",
        thClass: "thClass p30",
        tdClass: "tdClass p30",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    basicColumns: [
      {
        label: "이름",
        field: "mngrNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "아이디",
        field: "mngrId",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "지역본부",
        field: "grpNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "단지",
        field: "sbdNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "업체",
        field: "frmNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "역할",
        field: "rolNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    usConnColumns: [
      {
        label: "이름",
        field: "mngrNm",
        thClass: "thClass p20",
        tdClass: "tdClass p20",
      },
      {
        label: "아이디",
        field: "mngrId",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "지역본부",
        field: "grpNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "단지",
        field: "sbdNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "업체",
        field: "frmNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "역할",
        field: "rolNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "내역보기",
        field: "hst",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    usConnHstColumns: [
      {
        label: "일시",
        field: "actDttm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "메뉴",
        field: "mnuNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "행위",
        field: "mnuFncCd",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "IP",
        field: "ipad",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    batchHstColumns: [
      {
        label: "프로시저명",
        field: "procNm",
        thClass: "thClass p25",
        tdClass: "tdClass p25",
      },
      {
        label: "주기코드",
        field: "podNm",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "주기시간",
        field: "podHr",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "실행일정",
        field: "runScd",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "실행순서",
        field: "runSqn",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "사용여부",
        field: "usYn",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "DBMS",
        field: "dbmsDs",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "내역보기",
        field: "hst",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    rows: [],
    // 검색
    searchTerm: "",
    totalRows: null,
    pageSize: 15,
    pageNum: 1,
  },
  batchHstTabled: {
    batchHstDtlColumns: [
      {
        label: "시작일시",
        field: "stDttm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "종료일시",
        field: "edDttm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "실행타입",
        field: "runTyNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "프로시저 대상",
        field: "procTrg",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "프로시저 대상 시각",
        field: "procTrgTm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "소요시간",
        field: "tatm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "결과코드",
        field: "rslNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "결과레코드수",
        field: "rslRecCnt",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    rows: [],
    searchTerm: "",
    totalRows: null,
    pageSize: 15,
    pageNum: 1,
  },
  mngrInfo: {
    mngrNm: "",
    rolNm: "",
    sbdNm: "",
  },
  cldOprtInfo: {
    stndYm: "", // 기준년월
    pclFctsSmr: "", // 특이사항요약
    attachFile: null, // 파일개체
    svFlNm: null, // 파일이름
    otxtFlNm: null, // 파일 경로
  },
  result: {
    resCd: "",
    resMsg: "",
    resData: "",
  },
};
