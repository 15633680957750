export default [{
        path: "/usrAdm/usrAdm/usrList",
        name: "usr-list",
        component: () =>
            import ("@/views/usrAdm/usrAdm/usrList.vue"),
    },
    {
        path: "/usrAdm/usrAdm/usrDtl",
        name: "usr-dtl",
        component: () =>
            import ("@/views/usrAdm/usrAdm/usrDtl.vue"),
    },
    {
        path: "/usrAdm/usrAdm/usrUpd",
        name: "usr-upd",
        component: () =>
            import ("@/views/usrAdm/usrAdm/usrUpd.vue"),
    },
];