export default {

  // API 가져올 옵션값
  commCodeData: {
    filterOptions: [
      { value: null, text: '전체' },
      { value: 'userName', text: '이름' },
      { value: 'UserId', text: '아이디' },
    ],
    // 이름/아이디 셀렉트 옵션
    nameIdOptions: [
      { value: null, text: '전체' },
      { value: 'userIdName', text: '이름/아이디' },
      { value: 'userTel', text: '전화번호' },
    ],
    // 지역 셀렉트 옵션
    areaOptions: [
      { value: null, text: '전체' },
      { value: 'seoul', text: '서울' },
      { value: 'kyunggi', text: '경기' },
    ],
    // 권한 셀렉트 옵션
    authOptions: [
      { value: null, text: '전체' },
      { value: 'headOffice', text: '본사' },
      { value: 'regionOffice', text: '지역본부' },
    ],
  },

  selectedData: {
    filterOptions: { value: null, text: '전체' },
    nameIdOptions: { value: null, text: '전체' },
    areaOptions: { value: null, text: '전체' },
    authOptions: { value: null, text: '전체' },
  },
  /**
     * 리스트 테이블 관련 state
     */
  tabled: {
    columns: [{
      label: '이름',
      field: 'nm',
      thClass: 'thClass',
      tdClass: 'tdClass',
    },
    {
      label: '아이디',
      field: 'id',
      thClass: 'thClass',
      tdClass: 'tdClass',
    },
    {
      label: '지역',
      field: 'area',
      thClass: 'thClass',
      tdClass: 'tdClass',
    },
    {
      label: '단지명',
      field: 'sbd',
      thClass: 'thClass',
      tdClass: 'tdClass',
    },
    {
      label: '권한',
      field: 'agt',
      thClass: 'thClass',
      tdClass: 'tdClass',
    }, {
      label: '전화번호',
      field: 'tlno',
      thClass: 'thClass',
      tdClass: 'tdClass',
    }, {
      label: '이메일',
      field: 'eml',
      thClass: 'thClass',
      tdClass: 'tdClass',
    },
    {
      label: '수정',
      field: 'edt',
      thClass: 'thClass',
      tdClass: 'tdClass',
    },
    ],
    rows: [],
    // 검색
    searchTerm: null,
  },
  result: {
    resCd: '',
    resMsg: '',
    resData: '',
  },
};
