import app from '@/main'
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'
import cookie from '@/libs/vue-cookies'
import { msgAlertBox } from '@/utils/modal'
import { ApiResponseCode, responseCodesToReturn } from '@/constants/api-response-code'
import store from '@/store'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axios, jwtOverrideConfig) {

    this.axiosIns = axios
    this.axiosIns.defaults.baseURL = process.env.VUE_APP_API_URL;
    this.axiosIns.defaults.withCredentials = true;
    this.axiosIns.defaults.headers.common['Accept'] = 'application/json';
    this.axiosIns.defaults.headers.common['Content-Type'] = 'application/json';
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // 로딩바 표시안할 경우 noSpinner: true
        if (!config.noSpinner) {
          store.commit('cmn/SET_IS_LOADING', true)
        }
        const accessToken = cookie.getAccessToken()
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          config.headers.Authorization = accessToken
        }
        return config
      },
      error => {
        store.commit('cmn/SET_IS_LOADING', false)
        return Promise.reject(error)
      },
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        store.commit('cmn/SET_IS_LOADING', false)
        if (response.data 
          && (responseCodesToReturn.includes(response.data.resCd)
            || this.jwtConfig.excludedEndpoints.includes(response.config.url)
        )) {
          return response
        } 
        // TODO 로그아웃시 토큰 삭제 API 에러 응답 방어 코드로 로그인 플로우 변경시 삭제 해야함
        /**
         *  기존 로그인 해제시 리프레시 토큰 업데이트 API(/token/update)를 호출
         *  -> 새로운 접속자의 토큰으로 리프레시 토큰 갱신
         *  -> 기존 접속자 로그아웃 시도
         *  -> 기존 접속자 리프레시 토큰으로 토큰 삭제 API 호출(/token/delete) 에러!!
         */
        else if (response.config 
          && response.config.url === this.jwtConfig.refreshDeleteEndpoint 
          && response.data.resCd === ApiResponseCode.DELETED_ERROR_OCCURRED
        ) {
          return response
        } 
        else {
          msgAlertBox(app, response.data.resMsg)
          // msgAlertBox(app, '에러가 발생하였습니다.</br>다시 시도해주십시오.')
          throw new Error(`${response.data.resMsg}(${response.data.resCd})`)
        }
      },
      error => {
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              cookie.saveAccessToken(r.data.resData.newAccessToken)

              this.onAccessTokenFetched(r.data.resData.newAccessToken)
            }).catch(e => {
              console.error(e)
              router.replace({ name: 'Login' }).then(() => {
                this.isAlreadyFetchingAccessToken = false
              }).catch({})
            }).finally(() => {
              store.commit('cmn/SET_IS_LOADING', false)
            })
          }

          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              // originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              originalRequest.headers.Authorization = `${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })

          store.commit('cmn/SET_IS_LOADING', false)

          return retryOriginalRequest
        }

        store.commit('cmn/SET_IS_LOADING', false)
        console.error(error)
        msgAlertBox(app, '에러가 발생하였습니다.</br>다시 시도해주십시오.')

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refshTkn: cookie.getRefreshToken()
    },
    {
      headers: {
        Authorization: cookie.getAccessToken(),
      },
    })
  }

  getAxiosWithAuth() {
    return this.axiosIns
  }

  async updateRefreshToken(...args) {
    return this.axiosIns.post(this.jwtConfig.refreshUpdateEndpoint, ...args)
  }

  async deleteRefreshToken(...args) {
    return this.axiosIns.delete(this.jwtConfig.refreshDeleteEndpoint, ...args)
  }

}
