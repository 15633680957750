const utils = {
  fnFindDefaultValue4Map(obj, findKey) {  // obj가 map형식으로 넘어온 것의 기본값 찾기
    const keys = Object.keys(obj); // map의 key들을 찾음.
    const result = {};

    // 각 객체들의 key만큼 for문 돌려
    keys.forEach((element) => {
      // 객체에서 key를 찾아서 for문 돌려(value들은 array로 가져오니까!!!)
      // array에 값이 있으면 돌려!
      const key = element;
      const defaultArr = [];

      if (obj[key].length !== 0) {
        obj[element].forEach((ele) => {
          if (ele[findKey] === "Y") {
            defaultArr.push(ele);
          }
        });
      }

      if (defaultArr.length > 0) {
        result[key] = defaultArr;
      }
    });
    return result;
  },fnFindDefaultValue4Array(obj, findKey) {  // obj가 array형식으로 넘어온 것의 기본값 찾기
    const result = [];

    // 배열 형태면 foreach 돌려서 꺼내옴
    // eslint-disable-next-line consistent-return
    obj.forEach((element) => {
      if (element[findKey] === "Y") {
        result.push(element);
        return false; // break
      }
    });
    return result;
  },fnExcelDown(res) {  // 엑셀 다운로드
    const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

    // 엑셀 태그 설정(다운로드 후 삭제됨)
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", decodeURI(res.headers.filename));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },fnAlert(obj, message, type) {   // sweet alert!!(이쁜 alert창을 띄워줌!)  // type에 'success'가 들어오면 성공 alert창이 나옴. 그 외에는 경고 alert창이 나옴.
    if (type !== undefined) {
      obj.$swal({
        text: message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-dark',
        },
        buttonsStyling: false,
      });
    } else {
      // type이 success가 아닌 경우
      obj.$swal({
        text: message,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-dark',
        },
        buttonsStyling: false,
      });
    }
  },fnConfirm(obj, title, html) { // sweet confirm!!(이쁜 confirm창을 띄워줌!!)
    return obj.$swal({
      title,
      html,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-dark',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    });
  },zeroPad(val,pad){
    let zero = '';
    for(var i = 0; i < pad; i++) {
      zero += '0';
    }
    return (zero + val).slice(-pad);
  },ymd2date(ymd){
    if(ymd && ymd.length==8){
      return new Date(ymd.substring(0,4)+"-"+ymd.substring(5,6)+"-"+ymd.substring(6,8));
    }else{
      return ymd;
    }
  },date2ymd(val){
    if( !val ){
      return val;
    }else if( val instanceof Date ){
      return val.getFullYear()+this.zeroPad(val.getMonth()+1,2)+this.zeroPad(val.getDate(),2);
    }else if( (typeof val)=="string" && val.length==10){
      return val.substring(0,4)+val.substring(5,7)+val.substring(8,10);
    }else{
      return val;
    }
  },findEntityByKey(list,col,value){
    let rv={};
    list.forEach(function(itm){
      if(itm[col]==value){
        rv=itm;
      }
    });
    return rv;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { utils };
