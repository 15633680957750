export default {
  /**
   * 리스트 테이블 관련 state
   */
  commCodeData: {
    PGM_DS_CD: [],
    SVC_CLU_SCH: ["제목", "내용"],
    ESN_YN: ["Y", "N"],
    DEL_YN: ["Y", "N"],
    SYS_SCH: ["업체명", "연동URL"],
  },
  selectedData: {
    pgmDsCd: null,
    sbdId: null,
    svcCluSch: "제목",
    esnYn: "",
    delYn: "",
    rgsDt: "",
    sysSch: "업체명",
  },
  tabled: {
    columns: [],
    rows: [],
    searchTerm: "",
    totalRows: null,
    pageSize: 15,
    pageNum: 1,
  },hNetCoInfo: {
    // 홈넷사 정보
    frmNm: "", // 홈넷사 명
    brn: "", // 사업자번호
    grpSn: "", // 지역
    grpNm: "", // 지역 이름
    sbdIds: [], // 스마트홈 단지 명
    lnkgUrl: "", // url
    lnkgKey: "", // api-key
    clientId: "", // ClientId
    secretKey: "", // secretKey
    frmEngNm: "", // 업체 영문
    frmTlno: "", // 전화번호
    frmRpsvNm: "", // 대표자명
    frmIcprNm: "", // 담당자명
    frmIcprEml: "", // 담당자 이메일
    zc: "", // 우편번호
    frmAdr: "", // 주소
  },
  serviceCluInfo: {
    svcCluSn: "",
    cluTl: "",
    cluCts: "",
    esnYn: "",
    delYn: "",
    rgprId: "",
    rgsDttm: "",
    updDttm: "",
    updrId: "",
  },
  lnkgSysInfo: {
    hnetFrmSn: "",
    frmTyCd: "",
    hnetApiRspCd: "",
    hnetApiRspNm: "",
    frmNm: "",
    frmIcprNm: "",
    frmTlno: "",
    frmIcprEml: "",
    rspDttm: "",
    mthNm: "",
    sbdId: "",
    rqWasNm: "",
    lnkgUrl: "",
    oauthClntId: "",
    oauthScrtkey: "",
  },
};
