import { instanceWithAuth } from './index'

export const sysSetAxiosCall = {
  fetchHNetCoList(payload) {
    return instanceWithAuth.get(
      `sysmngr/hnets?pageSize=${payload.pageSize}&pageNum=${payload.pageNum}&searchTerm=${payload.searchTerm}`
    );
  },
  fetchHNetCoInfoSave(payload) {
    return instanceWithAuth.post(`sysmngr/hnets`, payload);
  },
  fetchHNetCoInfoUpdate(payload) {
    return instanceWithAuth.put(`sysmngr/hnets`, payload);
  },
  fetchHNetCoDelete(hnetFrmSns) {
    return instanceWithAuth.delete(`sysmngr/hnets`, {
      data: {
        hnetFrmSns,
      },
    });
  },
  fetchHNetCoInfo(payload) {
    return instanceWithAuth.get(`sysmngr/hnets/${payload}`);
  },
  fetchHNetCoApiKey() {
    return instanceWithAuth.get(`sysmngr/hnets-key`);
  },
  fetchOtherSysColumns() {
    return instanceWithAuth.post("list");
  },
  fetchOtherSysList() {
    return instanceWithAuth.post("list");
  },
  fetchBoardNoticeList(payload) {
    return instanceWithAuth.get(
      `mngr/boards/common-notice?pageSize=${payload.pageSize}&pageNum=${payload.pageNum}&natTl=${payload.searchTerm}`
    );
  },
  fetchBoardNoticeSave(payload) {
    return instanceWithAuth.post("mngr/boards/common-notice", payload);
  },
  fetchBoardNoticeUpdate(payload) {
    return instanceWithAuth.put("mngr/boards/common-notice", payload);
  },
  fetchnatSnInfo(payload) {
    return instanceWithAuth.get(`mngr/boards/common-notice/${payload.pgmDsCd}/${payload.natSn}`);
  },
  fetchBoardNoticeDelete(natSns) {
    return instanceWithAuth.delete(`mngr/boards/common-notice`, {
      data: {
        natSns,
      },
    });
  },
  fetchServiceCluList(payload) {
    return instanceWithAuth.get('mngr/boards/terms/SVC', {
      params: {
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
        cluTl: payload.cluCts,
        cluCts: payload.cluCts,
        esnYn: payload.esnYn,
        delYn: payload.delYn,
        rgsDt: payload.rgsDt
      }
    })
  },
  fetchServiceCluInfo(payload) {
    return instanceWithAuth.get(`mngr/boards/terms/SVC/${payload}`);
  },
  fetchServiceCluSave(payload) {
    return instanceWithAuth.post("mngr/boards/terms/SVC", payload);
  },
  fetchServiceCluUpdate(payload) {
    return instanceWithAuth.put("mngr/boards/terms/SVC", payload);
  },
  fetchServiceCluDelete(svcCluSns) {
    return instanceWithAuth.delete(`mngr/boards/terms/SVC`, {
      data: {
        svcCluSns,
      },
    });
  },
  fetchLnkgSysList(payload) {
    return instanceWithAuth.get('sysmngr/linkSystem', {
      params: {
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
        frmNm: payload.frmNm,
        lnkgUrl: payload.lnkgUrl
      }
    });
  },
  fetchLnkgSysInfo(payload) {
    return instanceWithAuth.get(`sysmngr/linkSystem/${payload}`);
  },
};
export default { sysSetAxiosCall };
