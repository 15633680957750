import { instanceWithAuth } from './index'

const config = {
  comn: "mngr/comn/",
};

export const cmnAxiosCall = {
  fetchNavMenu() {
    return instanceWithAuth.get(`${config.comn}navmenu`);
  },
  insertMnuLog(payload) {
    return instanceWithAuth.post(`mngr/scl`, payload);
  },
  // 공통코드 리스트
  fetchComnCode(payload) {
    return instanceWithAuth.get(`${config.comn}codes/${payload.cdGrpId}`,{params: payload});
  },
};
export default { cmnAxiosCall };
