// state에 선언된 변수에 값을 할당함.
export default {
  SET_H_NET_CO_LIST_COLUMN_DATA(state) {
    state.tabled.columns = []; // 초기화
    state.tabled.columns.push(
      {
        label: "홈넷사명",
        field: "frmNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "URL",
        field: "lnkgUrl",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "API-KEY",
        field: "lnkgKey",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );
    /* state.tabled.columns.push({
                                  label: '홈넷사명',
                                  field: 'nm',
                                  thClass: 'thClass',
                                  tdClass: 'tdClass',
                                },
                                {
                                  label: 'URL',
                                  field: 'id',
                                  thClass: 'thClass',
                                  tdClass: 'tdClass',
                                },
                                {
                                  label: '지역',
                                  field: 'area',
                                  thClass: 'thClass',
                                  tdClass: 'tdClass',
                                },
                                {
                                  label: '단지명',
                                  field: 'sbd',
                                  thClass: 'thClass',
                                  tdClass: 'tdClass',
                                },
                                {
                                  label: 'API-KEY',
                                  field: 'agt',
                                  thClass: 'thClass',
                                  tdClass: 'tdClass',
                                }, {
                                  label: '상태여부',
                                  field: 'tlno',
                                  thClass: 'thClass',
                                  tdClass: 'tdClass',
                                },
                                {
                                  label: '수정',
                                  field: 'edt',
                                  thClass: 'thClass',
                                  tdClass: 'tdClass',
                                }); */
  },
  SET_H_NET_CO_LIST_ROWS_DATA(state, payload) {
    state.tabled.columns = []; // 초기화
    state.tabled.columns.push(
      {
        label: "일련번호",
        field: "hnetFrmSn",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "홈넷사명",
        field: "frmNm",
        thClass: "thClass p25",
        tdClass: "tdClass p25",
      },
      {
        label: "URL",
        field: "lnkgUrl",
        thClass: "thClass p20",
        tdClass: "tdClass p20",
      },
      {
        label: "API-KEY",
        field: "lnkgKey",
        thClass: "thClass p30",
        tdClass: "tdClass p30",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );

    state.tabled.rows = [];
    state.tabled.rows = payload.map((v) => ({
      hnetFrmSn: v.hnetFrmSn,
      frmNm: v.frmNm,
      lnkgUrl: v.lnkgUrl,
      lnkgKey: v.lnkgKey,
    }));

    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_H_NET_CO_INFO_RESET(state) {
    state.hNetCoInfo.frmNm = "";
    state.hNetCoInfo.brn = "";
    state.hNetCoInfo.grpSn = "";
    state.hNetCoInfo.grpNm = "";
    state.hNetCoInfo.sbdIds = [];
    state.hNetCoInfo.lnkgUrl = "";
    state.hNetCoInfo.lnkgKey = "";
    state.hNetCoInfo.clientId = "";
    state.hNetCoInfo.secretKey = "";
    state.hNetCoInfo.frmEngNm = "";
    state.hNetCoInfo.frmTlno = "";
    state.hNetCoInfo.frmRpsvNm = "";
    state.hNetCoInfo.frmIcprNm = "";
    state.hNetCoInfo.frmIcprEml = "";
    state.hNetCoInfo.zc = "";
    state.hNetCoInfo.frmAdr = "";
  },
  SET_H_NET_CO_INFO_DATA(state, payload) {
    state.hNetCoInfo.hnetFrmSn = payload.hnetFrmSn;
    state.hNetCoInfo.frmNm = payload.frmNm;
    state.hNetCoInfo.brn = payload.brn;
    state.hNetCoInfo.grpSn = payload.grpSn;
    state.hNetCoInfo.grpNm = payload.grpNm;

    if (payload.sbdInfos[0].sbdId !== null) {
      state.hNetCoInfo.sbdIds = payload.sbdInfos.map((v) => ({
        text: v.sbdNm,
        value: v.sbdId,
      }));
    } else {
      state.hNetCoInfo.sbdIds = [];
    }

    state.hNetCoInfo.lnkgUrl = payload.lnkgUrl;
    state.hNetCoInfo.lnkgKey = payload.lnkgKey;
    state.hNetCoInfo.clientId = payload.clientId;
    state.hNetCoInfo.secretKey = payload.secretKey;
    state.hNetCoInfo.frmEngNm = payload.frmEngNm;
    state.hNetCoInfo.frmTlno = payload.frmTlno;
    state.hNetCoInfo.frmRpsvNm = payload.frmRpsvNm;
    state.hNetCoInfo.frmIcprNm = payload.frmIcprNm;
    state.hNetCoInfo.frmIcprEml = payload.frmIcprEml;
    state.hNetCoInfo.zc = payload.zc;
    state.hNetCoInfo.frmAdr = payload.frmAdr;
  },
  SET_H_NET_CO_API_KEY(state, payload) {
    state.hNetCoInfo.lnkgKey = payload.apiKey;
  },
  SET_BOARD_NOTICE_LIST_ROWS_DATA(state, payload) {
    state.tabled.columns = [];
    state.tabled.columns.push(
      {
        label: "번호",
        field: "natSn",
        thClass: "thClass p5",
        tdClass: "tdClass p5",
      },
      {
        label: "공지일자",
        field: "rgsNcDttm",
        thClass: "thClass p20",
        tdClass: "tdClass p20",
      },
      {
        label: "구분",
        field: "pgmDsCd",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "제목",
        field: "natTl",
        thClass: "thClass p40",
        tdClass: "tdClass p40",
      },
      {
        label: "등록자",
        field: "anncRgprNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );
    state.tabled.rows = [];
    state.tabled.rows = payload.map((v) => ({
      rgsNcDttm: v.rgsNcDttm,
      pgmDsCd : v.pgmDsCd,
      natTl: v.natTl,
      ntcFctsNo: v.ntcFctsNo,
      natSn: v.natSn,
      anncRgprNm: v.anncRgprNm,
    }));
    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_BOARD_NOTICE_LIST_RESET(state) {
    state.tabled.columns = [];
    state.tabled.columns.push(
      {
        label: "번호",
        field: "natSn",
        thClass: "thClass p5",
        tdClass: "tdClass p5",
      },
      {
        label: "공지일자",
        field: "rgsNcDttm",
        thClass: "thClass p20",
        tdClass: "tdClass p20",
      },
      {
        label: "구분",
        field: "pgmDsCd",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "제목",
        field: "natTl",
        thClass: "thClass p40",
        tdClass: "tdClass p40",
      },
      {
        label: "등록자",
        field: "anncRgprNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );
    state.tabled.rows = [];
  },
  SET_BOARD_NOTICE_PGM_CD_DATA(state, payload) {
    state.commCodeData[payload.key] = payload.data.map((v) => ({
      value: v.value,
      text: v.name,
    }));
  },
  SET_BOARD_NOTICE_FCTS_SN_DATA(state, payload) {
    state.natSnInfo = [];
    state.natSnInfo = payload; // 상세보기
  },
  SET_SERVICE_CLU_LIST_ROWS_DATA(state, payload) {
    state.tabled.columns = [];
    state.tabled.columns.push(
      {
        label: "일련번호",
        field: "svcCluSn",
        thClass: "thClass hidden",
        tdClass: "tdClass hidden",
      },
      {
        label: "등록일자",
        field: "rgsDt",
        thClass: "thClass p20",
        tdClass: "tdClass",
      },
      {
        label: "제목",
        field: "cluTl",
        thClass: "thClass p30",
        tdClass: "tdClass",
      },
      {
        label: "필수여부",
        field: "esnYn",
        thClass: "thClass p15",
        tdClass: "tdClass",
      },
      {
        label: "삭제여부",
        field: "delYn",
        thClass: "thClass p15",
        tdClass: "tdClass",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );
    state.tabled.rows = payload;
    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_SERVICE_CLU_LIST_RESET(state) {
    state.tabled.rows = [];
  },
  SET_SERVICE_CLU_INFO_DATA(state, payload) {
    state.serviceCluInfo = payload;
  },
  SET_SERVICE_CLU_INFO_RESET(state) {
    state.serviceCluInfo = [];
  },
  SET_LNKG_SYS_LIST_ROWS_DATA(state, payload) {
    state.tabled.columns = [];
    state.tabled.columns.push(
      {
        label: "업체 일련번호",
        field: "hnetFrmSn",
        thClass: "thClass hidden",
        tdClass: "tdClass hidden",
      },
      {
        label: "업체명",
        field: "frmNm",
        thClass: "thClass p20",
        tdClass: "tdClass",
      },
      {
        label: "시스템 URL",
        field: "lnkgUrl",
        thClass: "thClass p25",
        tdClass: "tdClass",
      },
      {
        label: "업체주소",
        field: "frmAdr",
        thClass: "thClass p20",
        tdClass: "tdClass",
      },
      {
        label: "상태여부",
        field: "hnetApiRspCd",
        thClass: "thClass p10",
        tdClass: "tdClass",
      },
      {
        label: "담당자명",
        field: "frmIcprNm",
        thClass: "thClass p10",
        tdClass: "tdClass",
      },
      {
        label: "전화번호",
        field: "frmTlno",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );
    state.tabled.rows = payload;
    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_LNKG_SYS_INFO_DATA(state, payload) {
    state.lnkgSysInfo = payload;
  },
  SET_RESULT(state, payload) {
    state.result = payload;
  },
};
