export default {
  lhwNavMenu: [],
  // API 가져올 옵션값
  commCodeData: {
    FILTER_OPTION: [
      { value: "mngrNm", text: "이름" },
      { value: "mngrId", text: "아이디" },
      { value: "grpSn", text: "지역본부" },
      { value: "sbdId", text: "단지" },
      { value: "frmSn", text: "업체" },
      { value: "rolId", text: "역할" },
      { value: "mngrSsNm", text: "상태" },
    ],
    ROL_ID: [], // 역할
    GRP_SN: [], // 지역
    SBD_ID: [], // 단지
    FRM_SN: [], // 업체리스트
    //WID_SN: [], // 광역
    SMAH_SBD_ID: [], // 광역
  },
  // 셀렉트 박스트 text
  selectedData: {
    rolId: null,
    grpSn: null,
    sbdId: null,
    frmSn: null,
    widSn: null,
  },
  // 셀렉박스의 value
  selectedListData: {
    rolId: "",
    grpSn: "",
    sbdId: "",
    frmSn: "",
    //widSn: "",
  },
  selectedListBoolean: {
    grpSnBoolean: false, // 지역 노출 여부
    warSnBoolean: false, //  광역 노출여부
    sbdIdBoolean: false, // 단지 노출여부
    frmSnBoolean: false, // 업체 노출여부
    widSnBoolean: false, // 업체 노출여부
  },
  /**
   * 리스트 테이블 관련 state
   */
  tabled: {
    columns: [
      {
        label: "이름",
        field: "mngrNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "아이디",
        field: "mngrId",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "지역본부",
        field: "grpNm",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "단지",
        field: "sbdNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "업체",
        field: "frmNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "역할",
        field: "rolNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "상태",
        field: "mngrSsNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    basicColumns: [
      {
        label: "이름",
        field: "mngrNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "아이디",
        field: "mngrId",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "지역본부",
        field: "grpNm",
        thClass: "thClass p10",
        tdClass: "tdClass p10",
      },
      {
        label: "단지",
        field: "sbdNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "업체",
        field: "frmNm",
        thClass: "thClass p15",
        tdClass: "tdClass p15",
      },
      {
        label: "역할",
        field: "rolNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "상태",
        field: "mngrSsNm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      },
    ],
    rows: [],
    // 검색
    searchTerm: "",
    totalRows: null,
    pageSize: 15,
    pageNum: 1,
  },
  rolTabled: {
    columns: [],
    rows: [],
    // 검색
    searchTerm: null,
    totalRows: null,
    pageSize: 10,
    pageNum: 1,
  },

  mngrIdInfo: {},
  rolIdInfo: {},
  result: {},
  // mngrIdInfo: {
  //   mngrId: "",
  //   rolId: "",
  //   mngrPwd: "",
  //   mngrNm: "",
  //   mngrMblTlno: "",
  //   mngrTlno: "",
  //   mngrEmlAdr: "",
  //   mngrSsCd: "",
  //   emlRcvYn: "",
  //   grpSn: "",
  //   widSn: "",
  //   sbdId: "",
  //   mngrPstnNm: "",
  //   mngrDtsNm: "",
  //   frmSn: "",
  //   rolNm: "",
  //   frmNm: "",
  //   grpNm: "",
  //   sbdNm: "",
  //   widNm: "",
  // },
  // rolIdInfo: {
  //   rolId: "",
  //   rolNm: "",
  //   rolDesc: "",
  // },
  // result: {
  //   resCd: "",
  //   resMsg: "",
  // },
};
