// state에 선언된 변수에 값을 할당함.
export default {
    SET_USR_LIST_COLUMN_DATA(state) {
        state.tabled.columns = [];
        state.tabled.columns.push({
            label: '이름',
            field: 'nm',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '아이디',
            field: 'id',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '지역',
            field: 'area',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '단지명',
            field: 'sbd',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '동',
            field: 'dng',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '호수',
            field: 'rmno',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '전화번호',
            field: 'time',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '이메일',
            field: 'eml',
            thClass: 'thClass',
            tdClass: 'tdClass',
        }, {
            label: '수정',
            field: 'edt',
            thClass: 'thClass',
            tdClass: 'tdClass',
        });
    },
    SET_USR_LIST_ROWS_DATA(state, payload) {
        state.tabled.rows = payload.resData;
        state.tabled.totalRows = payload.resData[0].totCnt;
    },
    SET_RESULT(state, payload) {
        state.result = payload;
    }
};