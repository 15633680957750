// state에 선언된 변수에 값을 할당함.
export default {
  SET_CMN_NAV_MENU_DATA(state, payload) {
    state.navMenu = payload;
  },
  SET_CMN_MNU_FNC_CDS_DATA(state, payload) {
    state.mnuFncCds = payload;
  },
  SET_CLK_MNUID(state, payload) {
    state.clkMnuId = payload;
  },
  SET_CLK_MNUNM(state, payload) {
    state.clkMnuNm = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload
  },
};
