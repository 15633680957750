export default {
  getCommCodeData(state) {
    return state.commCodeData;
  },
  getTabledCodeData(state) {
    return state.tabled;
  },
  getSelectedCodeData(state) {
    return state.selectedData;
  },
  getResult(state) {
    return state.result;
  },
};
